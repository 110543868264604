<template>
  <v-col
    class="app-empty-list">
    <template v-if="!networkError">
      <img
        class="app-empty-list__background-img"
        src="@/assets/icons/emptyProject.png"
        alt="empty list">
      <h1 class="mt-sm-10 mt-3 text-center">
        {{ mainHeading }}
      </h1>
      <h2
        :class="{
          'subtitle-1': from === 'community'
        }"
        class="mt-3 text-center">
        {{ subHeading }}
      </h2>
      <v-btn
        v-if="from === 'community'"
        class="mt-8"
        color="mainBlue"
        dark
        @click="startSearch">
        Start My Search
      </v-btn>
      <router-link
        v-if="from === 'pages'"
        :to="{ name: 'community-collections', params: { wId: activeWorkspaceId, id: 'community' }}"
        class="mt-8">
        <v-btn
          color="mainBlue"
          dark>
          View Your Community
        </v-btn>
      </router-link>
    </template>
    <h1
      v-else
      class="mt-10 text-center">
      Your workspace is loading. Please wait.
    </h1>
  </v-col>
</template>
<script>
import { mapState } from 'vuex';
import {
  EventBus,
  START_NEW_COMMUNITY_SEARCH,
} from '@/utils/eventBus';
export default {
  name: 'AppEmptyList',
  props: {
    from: {
      type: String,
      default: 'libraries',
    },
  },
  computed: {
    ...mapState(['networkError']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    mainHeading() {
      switch (this.from) {
      case 'libraries':
        return 'You don\'t have any Libraries yet';
      case 'library-collections':
        return 'You haven\'t added any collections yet';
      case 'collections':
        return 'You don\'t have any Collections yet';
      case 'community':
        return 'You haven\'t followed anything yet!';
      default:
        return 'You haven\'t followed any Pages yet';
      }
    },
    subHeading() {
      switch (this.from) {
      case 'libraries':
        return 'Create one and add some collections to it!';
      case 'library-collections':
        return 'Go follow some collections into this library!';
      case 'collections':
        return 'Create one and add some products to it!';
      case 'community':
        return `Don't worry, changing that is super simple! Just start searching
        the community to find manufacturers and collections that you would like
        to follow and envision using their products in your next projects.`;
      default:
        return 'Go to the community to follow pages.';
      }
    },
  },
  methods: {
    startSearch() {
      EventBus.$emit(START_NEW_COMMUNITY_SEARCH);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.app-empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  &__background-img {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 80%;
      height: auto;
    }
  }
}
</style>
